<template>
  <div class="componentSty">
    <div class="componentSty_content">
      <div class="componentSty_content_title">
        <el-form
          ref="conditionsFormRef"
          :model="conditions"
          inline
          size="small"
        >
          <el-form-item label="发货公司" prop="sendOwnerId">
            <el-select v-model="conditions.sendOwnerId" clearable filterable>
              <el-option
                :key="item.id"
                v-for="item in ownerOptions"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="发货联系人" prop="sendContacts">
            <el-select v-model="conditions.sendContacts" clearable filterable>
              <el-option
                v-for="item in contactsOptions"
                :key="item.name"
                :value="item.name"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="发货联系电话" prop="sendPhone">
            <el-input v-model="conditions.sendPhone" clearable> </el-input>
          </el-form-item>
          <el-form-item label="发货地址" prop="sendAddrCodeStr">
            <AreaSelect
              :checkStrictly="true"
              v-model="conditions.sendAddrCodeStr"
            ></AreaSelect>
          </el-form-item>

          <el-form-item label="收货公司" prop="receiveOwnerId">
            <el-select v-model="conditions.receiveOwnerId" clearable filterable>
              <el-option
                :key="item.id"
                v-for="item in ownerOptions"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="收货联系人" prop="receiveContacts">
            <el-select
              v-model="conditions.receiveContacts"
              clearable
              filterable
            >
              <el-option
                v-for="item in contactsOptions"
                :key="item.name"
                :value="item.name"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="收货联系电话" prop="receivePhone">
            <el-input v-model="conditions.receivePhone" clearable> </el-input>
          </el-form-item>
          <el-form-item label="收货地址" prop="receiveAddrCodeStr">
            <AreaSelect
              :checkStrictly="true"
              v-model="conditions.receiveAddrCodeStr"
            ></AreaSelect>
          </el-form-item>

          <el-collapse-transition>
            <div v-show="showMoreConditions">
              <el-form-item label="车队">
                <el-select v-model="conditions.fleetId" filterable>
                  <el-option
                    :key="item.id"
                    v-for="item in fleetOptions"
                    :value="item.id"
                    :label="item.name"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="货源编号" prop="pno">
                <el-input v-model="conditions.pno" clearable> </el-input>
              </el-form-item>
              <el-form-item label="货类" prop="cargoTypeId">
                <TreeSelect
                  :selectValue="conditions.cargoTypeId"
                  v-model="conditions.cargoTypeId"
                  @send="handleCargoTypeChange"
                ></TreeSelect>
              </el-form-item>
              <el-form-item label="货名" prop="cargoTypeNameId">
                <el-select
                  v-model="conditions.cargoTypeNameId"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="item in cargoTypeNameOptions"
                    :key="item.id"
                    :label="item.cargoName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="货物重量" prop="plantons">
                <el-input v-model="conditions.plantons" clearable> </el-input>
              </el-form-item>
              <el-form-item label="运费单价" prop="freightCharge">
                <el-input v-model="conditions.freightCharge" clearable>
                </el-input>
              </el-form-item>
              <el-form-item label="装车费" prop="loadingCharge">
                <el-input v-model="conditions.loadingCharge" clearable>
                </el-input>
              </el-form-item>
              <el-form-item label="卸车费" prop="unloadingCharge">
                <el-input v-model="conditions.unloadingCharge" clearable>
                </el-input>
              </el-form-item>
              <el-form-item label="车辆类型" prop="truckType">
                <el-select v-model="conditions.truckType" filterable clearable>
                  <el-option
                    v-for="item in truckTypeOptions"
                    :key="item.val"
                    :value="item.val"
                    :label="item.val"
                  ></el-option> </el-select
              ></el-form-item>
              <el-form-item label="发货详细地址" prop="sendAddressDetail">
                <el-input v-model="conditions.sendAddressDetail" clearable>
                </el-input>
              </el-form-item>
              <el-form-item label="收货详细地址" prop="receiveAddressDetail">
                <el-input v-model="conditions.receiveAddressDetail" clearable>
                </el-input>
              </el-form-item>
              <el-form-item label="委托人" prop="cargoAgent">
                <el-select v-model="conditions.cargoAgent" clearable filterable>
                  <el-option
                    v-for="item in contactsOptions"
                    :key="item.name"
                    :value="item.name"
                    :label="item.name"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="委托人联系电话" prop="cargoAgentPhone">
                <el-input v-model="conditions.cargoAgentPhone" clearable>
                </el-input>
              </el-form-item>
              <el-form-item label="分派状态" prop="dispatchStatus">
                <el-select
                  v-model="conditions.dispatchStatus"
                  clearable
                  multiple
                  collapse-tags
                >
                  <el-option
                    v-for="(item, index) in dispatchStatusEnum"
                    :key="index"
                    :value="index"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="装货日期" prop="loadingDate">
                <el-date-picker
                  v-model="conditions.loadingDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  style="width: 208px"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="发布日期" prop="publishTime">
                <el-date-picker
                  v-model="conditions.publishTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  style="width: 208px"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="创建时间" prop="gmtCreate">
                <el-date-picker
                  v-model="conditions.gmtCreate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  style="width: 208px"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="装卸要求" prop="loadRequirement">
                <el-input v-model="conditions.loadRequirement" clearable>
                </el-input>
              </el-form-item></div
          ></el-collapse-transition>
          <el-form-item>
            <el-button type="primary" class="buttons" @click="handleSearch"
              >查询</el-button
            >
            <el-button
              type="info"
              plain
              class="buttons"
              @click="handleReset('conditionsFormRef')"
              >重置</el-button
            >
            <el-button
              type="primary"
              plain
              class="buttons"
              @click="handleCollapse"
              >{{ showMoreConditions ? "收起" : "更多" }}</el-button
            >
          </el-form-item>
          <el-form-item style="float: right">
            <el-dropdown
              trigger="click"
              style="margin-right: 10px"
              @command="handleCommand"
            >
              <el-button type="primary">
                导出<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="a">全部导出</el-dropdown-item>
                <el-dropdown-item command="b">批量导出</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-form-item>
        </el-form>
      </div>
      <div class="componentSty_content_table" style="top: 90px; border: none">
        <el-table
          border
          :data="tableList"
          style="width: 100%"
          ref="tableRef"
          row-key="id"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            reserve-selection
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="序号"
            type="index"
            width="50px"
            :index="indexMethod"
          ></el-table-column>
          <el-table-column
            min-width="150px"
            show-overflow-tooltip
            label="发货公司"
            prop="sendOwner"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            min-width="100px"
            label="发货联系人"
            prop="sendContacts"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            min-width="120px"
            label="发货联系电话"
            prop="sendPhone"
          >
          </el-table-column>
          <el-table-column
            label="发货地址"
            show-overflow-tooltip
            min-width="80px"
          >
            <template v-slot="scope">
              {{
                scope.row.sendProvince +
                "/" +
                scope.row.sendCity +
                "/" +
                scope.row.sendCounty
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="发货详细地址"
            min-width="120px"
            prop="sendAddressDetail"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="收货公司"
            prop="receiveOwner"
            min-width="150px"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="收货联系人"
            min-width="100px"
            prop="receiveContacts"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="收货联系电话"
            min-width="120px"
            prop="receivePhone"
          >
          </el-table-column>
          <el-table-column label="收货地址" show-overflow-tooltip>
            <template v-slot="scope">
              {{
                scope.row.receiveProvince +
                "/" +
                scope.row.receiveCity +
                "/" +
                scope.row.receiveCounty
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="收货详细地址"
            min-width="120px"
            show-overflow-tooltip
            prop="receiveAddressDetail"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="货物类别"
            prop="cargoType"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="货物名称"
            prop="cargoTypeName"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="货物重量"
            prop="plantons"
            min-width="100px"
          >
            <template v-slot="scope">
              {{ scope.row.plantons + "吨" }}
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="运费单价"
            prop="freightCharge"
          >
            <template v-slot="scope">
              {{ scope.row.freightCharge + "元/吨" }}
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="货源编号"
            prop="pno"
            min-width="180px"
          >
          </el-table-column>

          <el-table-column
            show-overflow-tooltip
            label="发布时间"
            prop="publishTime"
            min-width="160px"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="创建时间"
            prop="gmtCreate"
            min-width="160px"
          >
          </el-table-column>

          <el-table-column
            show-overflow-tooltip
            label="发布去向"
            min-width="100px"
            fixed="right"
          >
            <template v-slot="scope">
              {{ openEnum[scope.row.isopen] || "" }}
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="分派状态"
            prop=""
            fixed="right"
          >
            <template v-slot="scope">
              <el-tag
                size="small"
                :type="dispatchStatusEnum[scope.row.fleetDispatchStatus].type"
                v-if="dispatchStatusEnum[scope.row.fleetDispatchStatus]"
                >{{
                  dispatchStatusEnum[scope.row.fleetDispatchStatus].label
                }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="160" fixed="right">
            <template v-slot="scope">
              <el-button
                type="text"
                size="mini"
                @click.stop="goDetails(scope.row)"
              >
                详情
              </el-button>
              <el-button
                type="text"
                size="mini"
                @click.stop="goEdit(scope.row)"
              >
                分派调度
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :total="total"
          :page="conditions.page"
          :pageSize="conditions.limit"
          @paging="getPaging"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from "@/components/pagination.vue";
import TreeSelect from "@/components/treeSelect.vue";
import AreaSelect from "@/components/areaSelect.vue";
import lodash from "lodash";
export default {
  data() {
    return {
      conditions: {
        page: 1,
        limit: 10,
        fleetId: "",
        sendOwnerId: "",
        sendContacts: "",
        sendPhone: "",
        sendAddrCodeStr: [],
        sendAddressDetail: "",
        receiveOwnerId: "",
        receiveContacts: "",
        receivePhone: "",
        sendAddrCodeStr: [],
        sendAddressDetail: "",
        cargoTypeId: "",
        cargoTypeNameId: "",
        truckType: "",
        loadingCharge: "",
        unloadingCharge: "",
        plantons: "",
        freightCharge: "",
        dispatchStatus: [],
        pno: "",
        isopen: "",
        cargoAgent: "",
        cargoAgentPhone: "",
        loadingDate: "",
        publishTime: "",
        gmtCreate: "",
      },
      checkedIds: [],
      showMoreConditions: false,
      dispatchStatusEnum: {
        0: { label: "未分派", type: "info" },
        1: { label: "已分派", type: "primary" },
      },
      openEnum: {
        0: "货源大厅",
        1: "车队/司机",
      },
      fleetOptions: [],
      contactsOptions: [],
      ownerOptions: [],
      receiveOwnerId: [],
      cargoTypeNameOptions: [],
      truckTypeOptions: [],
      tableList: [],
      total: 0,
    };
  },
  components: {
    Pagination,
    TreeSelect,
    AreaSelect,
  },
  created() {
    this.getContactsOptions();
    this.getOwnerOptions();
    this.getTruckTypeOptions();
    this.getFleetOptions();
  },
  methods: {
    indexMethod(index) {
      return (this.conditions.page - 1) * this.conditions.limit + index + 1;
    },
    async getOwnerOptions() {
      const { data } = await this.$http(
        "api1",
        "/api/biz/EnterpriseInfo/list",
        "get",
        {}
      );
      this.ownerOptions = data.data.list;
    },
    async getFleetOptions() {
      const { data } = await this.$http(
        "api1",
        "/api/biz/FleetInfo/ownList",
        "get",
        {}
      );
      this.fleetOptions = data.data;
      if (this.fleetOptions.length > 0) {
        this.conditions.fleetId = this.fleetOptions[0].id;
        this.getList();
      }
    },
    transformConditions() {
      let clone = lodash.cloneDeep(this.conditions);
      clone.dispatchStatus = clone.dispatchStatus.toString();
      clone.sendAddrCodeStr = clone.sendAddrCodeStr.toString();
      return clone;
    },
    async getContactsOptions() {
      const { data } = await this.$http(
        "api1",
        "/api/biz/ContactsInfo/list",
        "get",
        {}
      );
      this.contactsOptions = data.data.list;
    },
    async getTruckTypeOptions() {
      const { data } = await this.$http("api1", "/sys/dic/list", "get", {
        dictionaryType: "车辆类型",
      });
      this.truckTypeOptions = data.data;
    },
    handleCollapse() {
      this.showMoreConditions = !this.showMoreConditions;
    },
    async handleCargoTypeChange(value) {
      this.conditions.cargoTypeNameId = "";
      if (value) {
        //this.addForm.cargoTypeId = value;
        const { data } = await this.$http(
          "api1",
          "/api/biz/CargoTypeInfo/cargoNameList",
          "get",
          {
            pid: this.conditions.cargoTypeId,
          }
        );
        this.cargoTypeNameOptions = data.data;
      } else {
        this.conditions.cargoTypeId = "";
      }
    },
    getPaging(val) {
      this.conditions.page = val.page;
      this.conditions.limit = val.pageSize;
      this.getList();
    },
    handleReset(formName) {
      this.$refs.tableRef.clearSelection();
      this.$refs[formName].resetFields();
      this.conditions.limit = 10;
      this.conditions.page = 1;
      this.getList();
      this.showMoreConditions = false;
    },
    // 获取列表数据
    async getList() {
      if (!this.conditions.fleetId) {
        return this.$message.error("暂无关联车队");
      }
      const { data } = await this.$http(
        "api1",
        "/api/biz/PlanFleet/fleetPlanPage",
        "get",
        this.transformConditions()
      );
      this.tableList = data.data.list;
      this.total = data.data.totalCount;
    },
    // 获取货场列表
    handleSearch() {
      this.$refs.tableRef.clearSelection();
      this.conditions.page = 1;
      this.getList();
    },
    goEdit(row) {
      this.$router.push({
        path: "/cargoSource/fleetCargoSourceDispatchEdit",
        query: {
          id: row.id,
          fleetId: this.conditions.fleetId,
          fleetName: this.fleetOptions.find(
            (x) => x.id == this.conditions.fleetId
          ).name,
        },
      });
    },
    goDetails(row) {
      this.$router.push({
        path: "/cargoSource/fleetCargoSourceDispatchDetails",
        query: { id: row.id, pno: row.pno, fleetId: this.conditions.fleetId },
      });
    },
    handleDelete(id) {
      this.$messageBox
        .confirm("确认删除该货源, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(async () => {
          const { data } = await this.$http(
            "api1",
            "/api/biz/PlanFleet/delete",
            "post",
            {
              ids: [id],
            }
          );
          if (data.code != 0) {
            return this.$message.error(data.msg);
          } else {
            this.$message.success("操作成功");
            this.getList();
          }
        });
    },
    async handleCommand(command) {
      if (!this.conditions.fleetId) {
        return this.$message.error("暂无关联车队");
      }
      if (command == "a") {
        this.$download(
          "post",
          "/api/biz/PlanFleet/fleetPlanExport",
          this.transformConditions()
        );
      } else if (command == "b") {
        if (!this.checkedIds || this.checkedIds.length == 0) {
          return this.$message.error("请选择要导出的数据");
        }
        this.$download("post", "/api/biz/PlanFleet/fleetPlanExport", {
          fleetId: this.conditions.fleetId,
          ids: this.checkedIds.toString(),
        });
      }
    },
    handleSelectionChange(val) {
      this.checkedIds = val.map((item) => item.id);
    },
  },
};
</script>
<style lang="scss" scoped></style>
